<template>
  <div>
    <!-- ÁREA DE INPUT DO USUÁRIO -->
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            title="1º Preencha os dados da operação"
          >
            <validation-observer ref="formSignal">
              <b-form @submit.prevent="submitForm">
                <b-row v-if="agentePerfis.length>1">
                  <b-col
                    cols="6"
                  >
                    <b-form-group label="Perfil">
                      <validation-provider
                        #default="{ errors }"
                        name="perfil"
                        rules="required"
                      >
                        <b-form-radio-group
                          v-model="sinal.agentePerfilTradeId"
                          :options="agentePerfis"
                          input-id="perfil"
                          text-field="nome"
                          value-field="id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group label="Posição">
                      <validation-provider
                        #default="{ errors }"
                        name="posicao"
                        rules="required"
                      >
                        <b-form-radio-group
                          v-model="sinal.ordemPosition"
                          :options="positionsOptions"
                          input-id="posicao"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Paridade</label>
                      <validation-provider
                        #default="{ errors }"
                        name="moedaParidade"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          v-model="paridadeEscolhida"
                          :options="paridadeOptions"
                          input-id="paridade"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label for="moeda">
                        Moeda
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Moeda"
                        :rules="{ required: true }"
                      >
                        <b-form-input
                          id="moeda"
                          v-model="sinal.moedaTrade"
                          @input="changeMoeda"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label>Alavancagem</label>
                      <vue-slider
                        v-model="sinal.alavancagem"
                        :adsorb="true"
                        :interval="1"
                        :marks="alavancOptions"
                        direction="ltr"
                        class="mb-2"
                        :min="1"
                        :max="alavancagemMax"
                        :disabled="!sinal.moedaTrade"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Tipo de ordem</label>
                      <validation-provider
                        #default="{ errors }"
                        name="tipoOrdem"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          v-model="sinal.ordemTipo"
                          :options="tipoOrdensOptions"
                          input-id="tipoOrdem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="sinal.ordemTipo !== 'market'"
                    cols="6"
                  >
                    <b-form-group>
                      <label>Valor de entrada</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Valor de entrada"
                        :rules="{ required: sinal.ordemTipo !== 'market', regex: /^[+]?\d+(\.\d+)?$/ }"
                      >
                        <b-form-input
                          id="valorEntrada"
                          v-model="sinal.valorCompra"
                          :disabled="sinal.ordemTipo=='market'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="sinal.ordemTipo === 'stop_limit'">
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Valor de Stop</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Valor de Stop"
                        :rules="{ required: sinal.ordemTipo === 'stop_limit', regex: /^[+]?\d+(\.\d+)?$/ }"
                      >
                        <b-form-input
                          id="valorEntradaStop"
                          v-model="sinal.valorEntradaStop"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group label="Alvo">
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <b-form-checkbox
                            v-model="alvoHabilitado"
                            plain
                            name="checkbox-input"
                          />
                        </b-input-group-prepend>
                        <validation-provider
                          #default="{ errors }"
                          name="Alvo"
                          :rules="{ required: alvoHabilitado }"
                        >
                          <b-form-input
                            id="alvo"
                            v-model="sinal.alvo1"
                            :disabled="!alvoHabilitado"
                            :placeholder="alvoHabilitado?'':'Sem alvo'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <label>Investir (%)</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Porcentagem"
                        :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: 0.03, max_value: 50 }"
                      >
                        <b-form-input
                          id="porcentagem"
                          v-model="sinal.porcentagem"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                  >
                    <b-form-group label="Stop Loss">
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <b-form-checkbox
                            v-model="stopLossHabiltado"
                            plain
                            name="checkbox-input"
                          />
                        </b-input-group-prepend>
                        <validation-provider
                          #default="{ errors }"
                          name="Stop loss"
                          :rules="{ required: stopLossHabiltado }"
                        >
                          <b-form-input
                            id="stoploss"
                            v-model="sinal.stopLoss"
                            :disabled="!stopLossHabiltado"
                            :placeholder="stopLossHabiltado?'':'Sem stoploss'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <!-- ÁREA DE RESUMO DA OPERAÇÃO -->

        <b-col
          cols="12"
          lg="6"
        >
          <b-card title="2º Revise e envie para seus assinantes">
            <b-row>
              <b-col class="mb-2">
                <b-row class="mb-1">
                  <b-col>
                    <b-row>
                      <b-col
                        cols="12"
                        lg="4"
                      >
                        <h5>Moeda</h5>
                        <span>
                          <b-avatar
                            v-if="sinal.moedaTrade"
                            :src="getIcon(sinal.moedaTrade)"
                          /> <strong>{{ paridade }}</strong>
                        </span>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="mt-1">
                            <span class="mr-1"> Volume em 24h: </span>
                            <span v-if="!sinal.moedaTrade"><strong>--</strong></span>
                            <span v-else-if="ticker!=null && ticker.vol"><strong>{{ ticker.vol }}</strong></span>
                            <span v-else><b-spinner small /></span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <span class="mr-1">Variação em 24h:</span>
                            <span v-if="!sinal.moedaTrade"><strong>--</strong></span>
                            <div
                              v-else-if="ticker"
                              class="d-inline"
                            >
                              <span
                                :class="ticker.percent >0? 'text-success' : 'text-danger'"
                              >
                                <strong>{{ ticker.percent }}% </strong>
                              </span>
                              <feather-icon
                                :icon="ticker.percent>0 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                                :class="[ticker.percent>0? 'text-success' : 'text-danger']"
                              />
                            </div>
                            <span v-else><b-spinner small /></span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col
                    class="mb-1"
                    lg="4"
                    cols="4"
                  >
                    <h5>Posição</h5>
                    <span
                      v-if="sinal.ordemPosition"
                      class="text-uppercase"
                    >
                      {{ sinal.ordemPosition }}
                    </span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col
                    class="mb-1"
                    lg="4"
                    cols="4"
                  >
                    <h5>Alavancagem</h5>
                    <span
                      v-if="sinal.alavancagem"
                      class="text-uppercase"
                    >
                      {{ sinal.alavancagem }}x
                    </span>
                    <span v-else>--</span>
                  </b-col>
                </b-row>
                <b-row class="mb-1 mt-2">
                  <b-col>
                    <h5>Valor de entrada</h5>
                    <span v-if="sinal.valorCompra">{{ sinal.valorCompra }}</span>
                    <span v-else>A mercado</span>
                  </b-col>
                  <b-col>
                    <h5>Preço atual</h5>
                    <span v-if="!sinal.moedaTrade"><strong>--</strong></span>
                    <span v-else-if="ticker">{{ ticker.price }}</span>
                    <span v-else><b-spinner small /></span>
                  </b-col>
                  <b-col>
                    <h5>Status</h5>
                    <span v-if="!sinal.moedaTrade || !sinal.valorCompra"><strong>--</strong></span>
                    <div v-else-if="ticker!=null && ticker.price!=null && sinal.valorCompra">
                      <template v-if="sinal.ordemPosition == 'long'">
                        <span
                          :class="parseFloat(ticker.price)>=parseFloat(sinal.valorCompra)? 'text-success' : 'text-danger'"
                        >
                          {{ percentDiferenca(sinal.valorCompra, ticker.price) }}%
                        </span>
                        <feather-icon
                          :icon="parseFloat(ticker.price)>=parseFloat(sinal.valorCompra)
                            ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                          :class="[parseFloat(ticker.price)>=parseFloat(sinal.valorCompra)? 'text-success' : 'text-danger']"
                        />
                      </template>
                      <template v-else>
                        <span
                          :class="parseFloat(sinal.valorCompra)<=parseFloat(ticker.price)? 'text-success' : 'text-danger'"
                        >
                          {{ percentDiferenca(ticker.price, sinal.valorCompra) }}%
                        </span>
                        <feather-icon
                          :icon="parseFloat(sinal.valorCompra)<=parseFloat(ticker.price)
                            ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                          :class="[parseFloat(sinal.valorCompra)<=parseFloat(ticker.price)? 'text-success' : 'text-danger']"
                        />
                      </template>
                    </div>
                    <span v-else><b-spinner small /></span>
                  </b-col>
                </b-row>
                <b-row class="mb-1 mt-2">
                  <b-col>
                    <h5>Alvo</h5>
                    <span>{{ sinal.alvo1 }}</span>
                    <span v-if="sinal.alvo1">
                      <template v-if="sinal.ordemPosition == 'long'">
                        <span
                          class="mr-1"
                          :class="parseFloat(valorCompra)<=parseFloat(sinal.alvo1)? 'text-success' : 'text-danger'"
                        >
                          ({{ percentDiferenca(valorCompra, sinal.alvo1) }})
                        </span>
                      </template>
                      <template v-else>
                        <span
                          class="mr-1"
                          :class="parseFloat(sinal.alvo1)<=parseFloat(valorCompra)? 'text-success' : 'text-danger'"
                        >
                          ({{ percentDiferenca(sinal.alvo1, valorCompra) }})
                        </span>
                      </template>
                    </span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col>
                    <h5>Stop Loss</h5>
                    <span>{{ sinal.stopLoss }}</span>
                    <span v-if="sinal.stopLoss">
                      <template v-if="sinal.ordemPosition == 'long'">
                        <span
                          class="mr-1"
                          :class="parseFloat(valorCompra)<=parseFloat(sinal.stopLoss)? 'text-success' : 'text-danger'"
                        >
                          ({{ getPercentDiff(valorCompra, sinal.stopLoss) }})
                        </span>
                      </template>
                      <template v-else>
                        <span
                          class="mr-1"
                          :class="parseFloat(sinal.stopLoss)<=parseFloat(valorCompra)? 'text-success' : 'text-danger'"
                        >
                          ({{ getPercentDiff(sinal.stopLoss, valorCompra) }})
                        </span>
                      </template>
                    </span>
                    <span v-else>--</span>
                  </b-col>
                  <b-col>
                    <h5>Investir (%)</h5>
                    <span v-if="sinal.porcentagem">{{ sinal.porcentagem }}</span>
                    <span v-else>--</span>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col>
                    <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="confirmarSalvar"
                    >
                      Enviar operação
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BFormInput, BButton, BAvatar, BSpinner, BOverlay, BFormRadioGroup, BInputGroup, BInputGroupPrepend, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { subscribeSymbol } from '@/services/binance/fut/binance'
import { percentDiferenca } from '@/utils/filter'
import VueSlider from 'vue-slider-component'
import novaOperacaoAbertaModule from './novaOperacaoModule'

const NOVA_OPERACAO_AGENTE_FUT_STORE_MODULE_NAME = 'nova-operacao-agente-fut'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BAvatar,
    BSpinner,
    BOverlay,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

    VueSlider,

  },

  mixins: [utilsMixin],

  data() {
    return {
      loadingOverlay: false,
      percentDiferenca,
      required,
      paridadeEscolhida: 'USDT',
      agentePerfis: [],
      sinal: {
        ordemPosition: 'long',
        moedaTrade: null,
        moedaParidade: null,
        ordemTipo: 'limit',
        agentePerfilTradeId: null,
        alvo1: null,
        stopLoss: null,
        valorCompra: null,
        alavancagem: 20,
        porcentagem: null,
      },
      alavancagemMax: 75,

      stopLossHabiltado: true,
      alvoHabilitado: true,

      positionsOptions: [
        { text: 'Long', value: 'long' },
        { text: 'Short', value: 'short' },
      ],

      paridadeOptions: [
        { text: 'USDT', value: 'USDT' },
      ],

      tipoOrdensOptions: [
        { text: 'Limite Stop', value: 'stop_limit' },
        { text: 'Limite', value: 'limit' },
        { text: 'Mercado', value: 'market' },
      ],
    }
  },

  computed: {

    alavancOptions() {
      let opt = []
      if (this.sinal.moedaTrade != null && this.paridadeEscolhida != null) {
        if (this.alavancagemMax === 125) {
          opt = [1, 10, 25, 50, 75, 100, 125]
        } else if (this.alavancagemMax === 100) {
          opt = [1, 10, 25, 50, 75, 100]
        } else if (this.alavancagemMax === 75) {
          opt = [1, 10, 20, 25, 50, 75]
        } else if (this.alavancagemMax === 50) {
          opt = [1, 10, 20, 30, 40, 50]
        } else {
          opt = [1, 10, 20, 30, 40, 50]
        }
      }
      return opt
    },

    valorCompra() {
      if (this.sinal.ordemTipo === 'limit') {
        return this.sinal.valorCompra
      }
      return this.ticker.price
    },

    paridade() {
      if (this.sinal.moedaTrade != null && this.paridadeEscolhida != null) {
        return this.sinal.moedaTrade + this.paridadeEscolhida
      }
      return ''
    },

    ticker() {
      if (this.paridade !== '') {
        const coin = this.paridade
        subscribeSymbol(coin)
        return this.$store.state.binanceFut.tickers[coin]
      }
      return ''
    },

  },

  created() {
    if (!store.hasModule(NOVA_OPERACAO_AGENTE_FUT_STORE_MODULE_NAME)) store.registerModule(NOVA_OPERACAO_AGENTE_FUT_STORE_MODULE_NAME, novaOperacaoAbertaModule)
    this.getAgentePerfis()
  },

  beforeDestroy() {
    if (store.hasModule(NOVA_OPERACAO_AGENTE_FUT_STORE_MODULE_NAME)) store.unregisterModule(NOVA_OPERACAO_AGENTE_FUT_STORE_MODULE_NAME)
  },

  methods: {

    changeMoeda() {
      this.sinal.moedaTrade = this.sinal.moedaTrade.toUpperCase()
      this.getAlavancagem(this.paridade)
    },

    confirmarSalvar() {
      this.$swal({
        title: 'Confirmar as alterações',
        text: 'Revise os valores. Ao clicar no botão verde, a operação será enviada para seus assinantes.',
        icon: 'warning',
        confirmButtonText: 'Ok, está tudo certo!',
        showCancelButton: true,
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.submitForm()
        }
      })
    },

    clearForm() {
      this.$refs.formSignal.reset()
      this.sinal = {
        moedaTrade: null,
        moedaParidade: null,
        ordemTipo: 'limit',
        alvo1: null,
        stopLoss: null,
        valorCompra: null,
        ordemPosition: 'long',
        alavancagem: 20,
        porcentagem: null,
        stopLossHabiltado: true,
      }
      if (this.agentePerfis.length === 1) {
        this.sinal.agentePerfilTradeId = this.agentePerfis[0].id
      }
    },

    getAgentePerfis() {
      this.$store.dispatch('nova-operacao-agente-fut/getAgentePerfis')
        .then(response => {
          this.agentePerfis = response.data.filter(a => a.produto === 'FUTURES')
          if (this.agentePerfis.length === 1) {
            this.sinal.agentePerfilTradeId = this.agentePerfis[0].id
          }
        })
    },

    getAlavancagem(moeda) {
      this.$store.dispatch('nova-operacao-agente-fut/getAlavancagem', { symbol: moeda })
        .then(response => {
          this.alavancagemMax = response.data.alavancagemMax
        })
    },

    submitForm() {
      this.$refs.formSignal.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.sinal.moedaParidade = this.sinal.moedaTrade + this.paridadeEscolhida

          if (this.stopLossHabiltado === false) {
            this.sinal.stopLoss = 'na'
          }

          if (this.alvoHabilitado === false) {
            this.sinal.alvo1 = 'na'
          }

          this.$store.dispatch('nova-operacao-agente-fut/abrirOperacao', this.sinal)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Sucesso',
                html: 'Operação enviada com sucesso',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              })
              this.clearForm()
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.data[0].erro,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.loadingOverlay = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-slider.scss';

</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
