import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    abrirOperacao(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('/fut/sinal', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getAgentePerfis() {
      return new Promise((resolve, reject) => {
        axios.get('agente/perfil')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getAlavancagem(context, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/interno/alavancagem', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
